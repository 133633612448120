<template>
    <section>
      <div class="py-4 px-2">
        <!-- Filtros -->
        <div class="w-full rounded-md shadow-md p-4 mb-4">
          <div class="field grid">
            <label for="nombre">Busqueda general</label>
            <div class="flex w-full lg:w-3/12">
              <InputText id="nombre" class="mb-2 w-full" type="text" placeholder="" />
              <Button class="border-0 ml-2 mb-2" @click="busqueda = false" icon="pi pi-search" style="width: 3.125rem;" />
            </div>
          </div>
        </div>
        <DataTable
          :value="recepciones"
          class="p-datatable-customers p-datatable-sm"
          dataKey="id"
          filterDisplay="menu"
          responsiveLayout="scroll"
          @sort="sort"
          :rowClass="rowClass"
        >
          <template #empty>
            No existen usuarios registradas
          </template>
          <Column field="id" header="ID" class="my-1 py-1 shadow-sm text-xs" headerClass="text-xs">
            <template #body="{data}">
              <div class="w-full h-full">
                <p class="text-xs">
                  {{ data.id }}
                </p>
              </div>
            </template>
          </Column>
          <Column field="guia" header="Guia" headerClass="text-xs" class="my-1 py-1 shadow-sm" style="min-width:10rem; color: #4A4A4A;">
            <template #body="{data}">
              <div class="w-full h-full">
                <p class="text-xs flex items-center">
                  {{ data.Guia}}
                </p>
              </div>
            </template>
          </Column>
          <Column field="oficio" header="Oficio" headerClass="text-xs" class="shadow-sm" style="min-width:10rem; color: #4A4A4A; font: 15px/18px;">
            <template #body="{data}">
            <p class="text-xs flex items-center">
              {{data.Oficio}}
            </p>
            </template>
          </Column>
          <Column header="Bodega Origen"
            headerClass="text-xs"
            dataType="date"
            style="min-width:10rem color: #4A4A4A; font: 15px/18px;"
            class="shadow-sm"
          >
            <template #body="{data}">
              <p class="text-xs flex items-center">
                {{ data.BodegaOrigen }}
              </p>
            </template>
          </Column>
          <Column header="Bodega Destino"
            headerClass="text-xs"
            dataType="date"
            style="min-width:10rem color: #4A4A4A; font: 15px/18px;"
            class="shadow-sm"
          >
            <template class="text-xs" #body="{data}">
              <p class="text-xs flex items-center">
                {{ data.BodegaDestino }}
              </p>
            </template>
          </Column>
          <Column header="Fecha Novedad"
            headerClass="text-xs text-center"
            dataType="date"
            style="min-width:10rem color: #4A4A4A; font: 15px/18px; text-align: center; justify-content: center;"
            class="shadow-sm text-center"
          >
            <template #body="{data}">
              <p class="text-xs flex items-center">
                {{ data.FechaEnvio }}
              </p>
            </template>
          </Column>
          <Column header="Novedades"
            headerClass="text-xs"
            style="min-width:10rem color: #4A4A4A; font: 15px/18px;"
            class="shadow-sm"
          >
            <template class="text-xs" #body="{data}">
              <div class="overflow-y-scroll max-h-20">
                <div v-for="(medicamento, i) in data.Medicamentos" :key="i" class="text-xs flex items-center">
                  <div class="flex"  v-for="(novedad, j) in medicamento.Novedades" :key="j">
                    <div class="p-1 bg-gray-600 text-white m-1">{{novedad.Tipo}}</div>
                  </div>
                </div>
              </div>
            </template>
          </Column>
          <Column header="Responsable recepción"
            headerClass="text-xs"
            dataType="date"
            style="min-width:10rem color: #4A4A4A; font: 15px/18px;"
            class="shadow-sm"
          >
            <template class="text-xs" #body="{data}">
              <p class="text-xs flex items-center">
                {{ data.ResponsableRecepcion }}
              </p>
            </template>
          </Column>
          <Column header="Acciones"
            headerClass="text-xs text-center"
            dataType="date"
            style="min-width:10rem color: #4A4A4A; font: 15px/18px; text-align: center; justify-content: center;"
            class="shadow-sm text-center"
          >
            <template #body="{data}">
              <Button v-if="data" @click="openModal(data.Medicamentos)" class="border-0 ml-2" icon="pi pi-eye" style="width: 2.2rem;" />
            </template>
          </Column>
        </DataTable>
        <Paginator v-model:first="offset"
                  :rows="limit"
                  :totalRecords="totalItemsCount"
                  :rowsPerPageOptions="[2,10,20,30]"
                  @page="onPage($event)"
        />
      </div>
    </section>
    <Dialog v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
      <template #header>
        <div>
          <h3 class="font-bold">Ver novedad</h3>
        </div>
      </template>
      <Accordion class="shadow-md gap-4" :activeIndex="0">
          <AccordionTab class="bg-red-600" v-for="(medicamento, k) in medicamentos" :key="k" headerClass="my-2">
            <template #header>
              <div class="flex justify-between w-full">
                <div class="flex">
                  <span class="font-bold mr-4">{{medicamento.Articulo}}</span>
                  <span class="font-bold mr-4">{{medicamento.Medicamento}}</span>
                </div>
                <div class="flex items-center">
                  <p class="mr-2">
                    CANTIDAD: {{medicamento.cantRec}}
                  </p>
                  <i class="pi pi-exclamation-circle text-red-600"></i>
                </div>
              </div>
            </template>
            <DataTable
              :value="medicamento.Novedades"
              responsiveLayout="scroll"
              headerClass="bg-white"
              showGridlines="true"
            >
              <template #empty>
                No existen usuarios registradas
              </template>
              <Column field="oficio" header="Novedad" headerClass="text-xs" class="shadow-sm" style="min-width:10rem; color: #4A4A4A; font: 15px/18px;">
                <template #body="{data}">
                <p class="text-xs flex items-center">
                  {{data.Tipo}}
                </p>
                </template>
              </Column>
              <Column field="oficio" header="Cantidad" headerClass="text-xs" class="shadow-sm" style="min-width:10rem; color: #4A4A4A; font: 15px/18px;">
                <template #body="{data}">
                <p class="text-xs flex items-center">
                  {{data.Cantidad}}
                </p>
                </template>
              </Column>
              <Column header="Observación"
                headerClass="text-xs"
                dataType="date"
                style="min-width:10rem color: #4A4A4A; font: 15px/18px;"
                class="shadow-sm"
              >
                <template class="text-xs" #body="{data}">
                  <p class="text-xs flex items-center">
                    {{ data.Observaciones }}
                  </p>
                </template>
              </Column>
            </DataTable>
          </AccordionTab>
      </Accordion>
      <template #footer>
          <Button label="No" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
          <Button label="Yes" icon="pi pi-check" @click="closeModal" autofocus />
      </template>
    </Dialog>
  </template>
  <script>
    import { ref, computed } from 'vue'
    export default {
    name: 'ComponenteNovedades',
    setup () {
      // References
      const offset = ref(0)
      const page = ref(0)
      const limit = ref(10)
      const totalItemsCount = ref(10)
      const displayModal = ref(false)
      const medicamentos = ref([])
      // Data
      const recepciones = ref([
        {
          id: '001',
          Guia: '125400126',
          Oficio: '8547',
          BodegaOrigen: 'Sede Bucaramanga',
          BodegaDestino: 'Sede BarrancaBermeja',
          ResponsableRecepcion: 'Juan Carlos Gutierrez',
          FechaEnvio: '12/12/2022',
          FechaEntrega: '08/01/2022',
          Estado: 'EN STOCK',
          Medicamentos: [
            {
              Articulo: 'MD083395',
              Medicamento: 'FOSTER 100/6MCG/DOSIS SOL INH FCO X 120 DOSIS',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 22,
              Novedades: [
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD083845',
              Medicamento: 'NITROFURANTOINA 100MG TAB REC CAJ X 40 BUSSIE',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 0,
              Novedades: [
                {
                  Tipo: 'Medicamento no enviado',
                  Cantidad: 0,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Medicamento no registrado',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD074595',
              Medicamento: 'TRESIBA 100UI SOL INY CAJ 1 CART X 3 ML',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 10,
              Novedades: [
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD083395',
              Medicamento: 'FOSTER 100/6MCG/DOSIS SOL INH FCO X 120 DOSIS',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 23,
              Novedades: [
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            }
          ]
        },
        {
          id: '001',
          Guia: '125400126',
          Oficio: '8547',
          BodegaOrigen: 'Sede Bucaramanga',
          BodegaDestino: 'Sede BarrancaBermeja',
          ResponsableRecepcion: 'Juan Carlos Gutierrez',
          FechaEnvio: '12/12/2022',
          FechaEntrega: '08/01/2022',
          Estado: 'EN STOCK',
          Medicamentos: [
            {
              Articulo: 'MD083845',
              Medicamento: 'NITROFURANTOINA 100MG TAB REC CAJ X 40 BUSSIE',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 0,
              Novedades: [
                {
                  Tipo: 'Medicamento no enviado',
                  Cantidad: 0,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Medicamento no registrado',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD074595',
              Medicamento: 'TRESIBA 100UI SOL INY CAJ 1 CART X 3 ML',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 10,
              Novedades: [
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD083395',
              Medicamento: 'FOSTER 100/6MCG/DOSIS SOL INH FCO X 120 DOSIS',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 23,
              Novedades: [
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            }
          ]
        },
        {
          id: '001',
          Guia: '125400126',
          Oficio: '8547',
          BodegaOrigen: 'Sede Bucaramanga',
          BodegaDestino: 'Sede BarrancaBermeja',
          ResponsableRecepcion: 'Juan Carlos Gutierrez',
          FechaEnvio: '12/12/2022',
          FechaEntrega: '08/01/2022',
          Estado: 'EN STOCK',
          Medicamentos: [
            {
              Articulo: 'MD083395',
              Medicamento: 'FOSTER 100/6MCG/DOSIS SOL INH FCO X 120 DOSIS',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 22,
              Novedades: [
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD083845',
              Medicamento: 'NITROFURANTOINA 100MG TAB REC CAJ X 40 BUSSIE',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 0,
              Novedades: [
                {
                  Tipo: 'Medicamento no enviado',
                  Cantidad: 0,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Medicamento no registrado',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD074595',
              Medicamento: 'TRESIBA 100UI SOL INY CAJ 1 CART X 3 ML',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 10,
              Novedades: [
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD083395',
              Medicamento: 'FOSTER 100/6MCG/DOSIS SOL INH FCO X 120 DOSIS',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 23,
              Novedades: [
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            }
          ]
        },
        {
          id: '001',
          Guia: '125400126',
          Oficio: '8547',
          BodegaOrigen: 'Sede Bucaramanga',
          BodegaDestino: 'Sede BarrancaBermeja',
          ResponsableRecepcion: 'Juan Carlos Gutierrez',
          FechaEnvio: '12/12/2022',
          FechaEntrega: '08/01/2022',
          Estado: 'EN STOCK',
          Medicamentos: [
            {
              Articulo: 'MD083395',
              Medicamento: 'FOSTER 100/6MCG/DOSIS SOL INH FCO X 120 DOSIS',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 22,
              Novedades: [
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD083845',
              Medicamento: 'NITROFURANTOINA 100MG TAB REC CAJ X 40 BUSSIE',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 0,
              Novedades: [
                {
                  Tipo: 'Medicamento no enviado',
                  Cantidad: 0,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Medicamento no registrado',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD074595',
              Medicamento: 'TRESIBA 100UI SOL INY CAJ 1 CART X 3 ML',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 10,
              Novedades: [
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            },
            {
              Articulo: 'MD083395',
              Medicamento: 'FOSTER 100/6MCG/DOSIS SOL INH FCO X 120 DOSIS',
              Id: '1254',
              lote: '014854',
              fechaVencimiento: '12/12/2023',
              cantEnv: 41,
              cantRec: 23,
              Novedades: [
                {
                  Tipo: 'Recepción Cantidad Menor',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                },
                {
                  Tipo: 'Deterioro',
                  Cantidad: 23,
                  Observaciones: 'En Físico solo llegaron 50 productos de lo cuales al realizar la recepción se presento que 3 estaban deteriorados'
                }
              ]
            }
          ]
        }
      ])
      // Computed
      const params = computed(() => {
        return {
          offset: offset.value,
          page: page.value,
          limit: limit.value
        }
      })
      // Methods
      const rowClass = (data) => {
        return data.id ? 'rowStyle' : null
      }
      const sort = (evt) => {
        console.log({ evt })
      }
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        // getEmpleados(params.value)
      }
      const openModal = (info) => {
        medicamentos.value = info
        displayModal.value = true
      }
      const closeModal = () => {
        displayModal.value = false
      }
      return {
        recepciones,
        rowClass,
        sort,
        onPage,
        totalItemsCount,
        offset,
        page,
        params,
        closeModal,
        displayModal,
        limit,
        openModal,
        medicamentos
      }
    }
  }
  </script>

  <style scoped>
  .p-button-por-recepcionar {
    color: #14999A;
    border: 1px solid #14999A;
  }
  .p-button-por-recepcionar:hover {
    color: #14999A;
    border: 1px solid #14999A;
  }
  .recepcion-stock {
    color: #43B62D;
  }
  .recepcion-por-recepcionar {
    color: #14999A;
  }
  .recepcion-en-transito {
    color: #6A6A6A;
  }
  ::-webkit-scrollbar {
    width: 0.2rem;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  </style>
